import { render, staticRenderFns } from "./AddBOM.vue?vue&type=template&id=463e02c5&"
import script from "./AddBOM.vue?vue&type=script&lang=js&"
export * from "./AddBOM.vue?vue&type=script&lang=js&"
import style0 from "./AddBOM.vue?vue&type=style&index=0&id=463e02c5&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports