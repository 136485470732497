<template>
  <div v-if="isPermission && isEditData">
    <CCard class="shadow-sm m-2">
      <CCardBody>
        <CRow>
          <CCol sm="1" col="2">
            <img src="/img/icon/store.png" style="width: 32px; height:32px;"/>
          </CCol>
          <CCol sm="9" col="7">
            <select class="custom-select text-dark" v-model="shop" disabled>
              <option v-for="shop in shops" :key="shop.objectId" :value="shop">
                {{ shop.shopName }} - {{ shop.branchName }}
              </option>
            </select>
            <div v-if="validateShop" class="text-danger">
              {{ validateShop }}
            </div>
          </CCol>
          <div class="col-md-2 col-sm-2 col-3 text-right">
            <CButton block color="light" @click="getback()">
              <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
            </CButton>
          </div>
        </CRow>
      </CCardBody>
    </CCard>
    <CCol md="12" lg="12" class="mt-4">
      <CCard class="shadow-sm">
        <CCardBody>
          <CAlert color="success" v-if="isSuccess === true">
            {{ $t("createProductSuccess") }}
          </CAlert>
          <div class="row">
            <div class="col-md-10 col-sm-9 col-9">
              <h4 class="font-weight-normal text-dark">{{ $t("addBOM") }}</h4>
              <h4 class="font-weight-normal text-dark">
                <span class="h4"> {{ $t("productName") }} : {{ SKUName }} </span>
              </h4>
            </div>
            <div class="col-md-2 col-sm-3 col-3 text-right">
              <CButton block color="success" @click="confirmSaveProductBOM">
                {{ $t("save") }}
              </CButton>
            </div>
          </div>
          <hr />

          <CRow>
            <CCol sm="6" lg="6" md="6">
              <CCard class="cardadd">
                <CCardBody>
                  <div>
                    <CInput
                      v-model.trim="keyword"
                      :placeholder="$t('searchByProduct')"
                      @input="getSKUbyType()"
                    />
                  </div>
                  <table class="table table-hover" style="width: 100%">
                    <tbody>
                      <div class="overflow-auto" style="max-height: 350px">
                        <tr
                          v-for="(item, index) in items"
                          :key="index"
                          hover
                          border
                          class="cursor"
                          striped
                          @click="addBOMtoLits(item, index)"
                        >
                          <td style="width: 7%">
                            <div
                              v-if="
                                item.remoteImagePath !== undefined &&
                                item.remoteImagePath !== '' &&
                                item.remoteImagePath !== null
                              "
                              class="square-box"
                              :style="{
                                'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
                                'background-size': 'cover',
                                'background-position': 'center center',
                                width: 'auto',
                                height: '40px',
                                'border-radius': '3px',
                                'aspect-ratio': '1.3',
                              }"
                            ></div>
                            <div
                              v-else
                              class="square-box"
                              :style="{
                                backgroundColor: item.colori,
                                width: 'auto',
                                'background-origin': 'content-box',
                                'border-radius': '3px',
                                height: '40px',
                                'aspect-ratio': '1.3',
                              }"
                            ></div>
                          </td>
                          <td>
                            <tr>
                              <span class="text-dark font-weight-normal">
                                {{ item.SKUName }}
                              </span>
                            </tr>
                            <tr>
                              <span class="text-dark font-weight-normal"
                                >{{ item.productPLU.PLUCode }}
                              </span>
                            </tr>
                            <tr>
                              <small class="text-dark font-weight-normal">
                                {{ item.unit.name }} / {{ item.SKURatio }}
                              </small>
                            </tr>
                          </td>

                          <td style="width: 2%" class="text-right text-dark">
                            <i class="fi fi-rr-angle-small-right"
                              @click="addBOMtoLits(item, index)"
                            ></i>
                          </td>
                        </tr>
                      </div>
                    </tbody>
                  </table>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol sm="6" lg="6" md="6">
              <CCard class="cardadd">
                <CCardHeader>
                  <CRow>
                    <CCol md="9" sm="9">
                      <h5 class="font-weight-normal">
                        {{ $t("productTypeBOM") }} ({{ countAddList }})
                      </h5>
                    </CCol>
                    <CCol md="3" sm="3" class="text-right"> </CCol>
                  </CRow>
                </CCardHeader>

                <CCardBody class="m-0" style="overflow-x: auto; max-width: 100%; padding: 0;">
                  <div
                    v-if="loadingButton"
                    style="overflow-x: auto; white-space:nowrap"
                  >
                    <table class="table table-hover" style="width: 100%">
                      <thead>
                        <tr>
                          <th class="text-dark" ></th>
                          <th class="text-dark font-weight-normal" >{{ $t("SKUName") }}</th>
                          <th class="text-dark text-center font-weight-normal"  >
                            {{ $t("ratioCount") }}
                          </th>
                          <th  class="text-dark text-center font-weight-normal">
                            {{ $t("quantityCount") }}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="loadingButton == true">
                          <tr
                            v-for="(item, index) in addlist"
                            :key="index"
                            hover
                            border
                            striped
                          >
                            <td style="width: 7%">
                              <div
                                v-if="
                                  item.remoteImagePath !== undefined &&
                                  item.remoteImagePath !== '' &&
                                  item.remoteImagePath !== null
                                "
                                class="square-box"
                                :style="{
                                  'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
                                  'background-size': 'cover',
                                  'background-position': 'center center',
                                  width: 'auto',
                                  height: '40px',
                                  'border-radius': '3px',
                                  'aspect-ratio': '1.3',
                                }"
                              ></div>
                              <div
                                v-else
                                class="square-box"
                                :style="{
                                  backgroundColor: item.colori,
                                  width: 'auto',
                                  'background-origin': 'content-box',
                                  'border-radius': '3px',
                                  height: '40px',
                                  'aspect-ratio': '1.3',
                                }"
                              ></div>
                            </td>
                            <td>
                              <div>
                                <span class="font-weight-normal text-dark">
                                  {{ item.SKUName }}
                                </span>
                              </div>
                              <div>
                                <span class="font-weight-normal text-dark">
                                  {{ item.productPLU.PLUCode }}
                                </span>
                              </div>
                              <div>
                                <small class="font-weight-normal text-dark">
                                  {{ item.unit.name }} / {{ item.SKURatio }}
                                </small>
                              </div>
                            </td>
                            <td style="width: 25%" class="">
                              <input
                                type="number"
                                min="0"
                                v-model.number="item.ratio"
                                class="form-control"
                                @input="calculate(item, 'quantity')"
                              />
                            </td>
                            <td style="width: 25%" class="text-center">
                              <input
                                type="number"
                                min="0"
                                v-model.number="item.quantity"
                                class="form-control"
                                @input="calculate(item, 'ratio')"
                              />
                            </td>

                            <td style="width: 8%">
                              <img src="/img/trash.png" style="width: 14px; height: 14px; cursor: pointer; "   @click="conremoveRow(item, index)">
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <modal
      color="success"
      :show.sync="confirmModal"
      :title="$t('confirmSaveImg')"
      v-on:isSave="saveProductBOM"
      centered
      :loading="loading"
    >
      <div>
        <h4 class="mt-4 mb-4 text-center">{{ $t("confirmSaveProduct") }}</h4>
      </div>
    </modal>
    <modal
      color="success"
      :show.sync="confirmSaveChangeModal"
      :title="$t('confirmSaveImg')"
      v-on:isSave="saveProductBOM"
      centered
      :loading="loading"
    >
      <div>
        <h4 class="mt-4 mb-2 text-center">{{ $t("confirmSaveChangeModal") }}</h4>
        <h4 class="mt-2 mb-2 text-center">{{ $t("confirmSaveChangeModal2") }}</h4>
        <h4 class="mt-2 mb-2 text-center">{{ $t("confirmSaveChangeModal3") }}</h4>
        <h4 class="mt-2 mb-4 text-center">{{ $t("confirmSaveChangeModal4") }}</h4>
      </div>
    </modal>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import util from "@/util/util";
import { mapGetters } from "vuex";
import local from "@/services/local";
import permis from "@/util/permission";

export default {
  components: {},
  data() {
    return {
      loading: true,
      loadingButton: true,
      isSuccess: false,
      SKUName: "",
      keyword: "",
      data: [],
      BOM: [],
      addlist: [],
      dataSKUbytype: [],
      validateShop: false,
      quantity: 1,
      ratio: 1,
      countAddList: 0,
      shop: this.$store.getters.shopObjectId,
      confirmModal: false,
      isFromBOM: false,
      originalBOM: [],
      confirmSaveChangeModal: false
    };
  },
  computed: {
    ...mapGetters(["shops", "users", "date"]),
    isPermission() {
      let path = "/product";
      return permis.findPermissionRead("product", path);
    },
    isEditData() {
      let path = "/product";
      return permis.findPermissionEdit("product", path);
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      return shop.uid;
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    gencolor(item) {
      let color = util.generateColor(item);
      return color;
    },
    items() {
      let detail = [];
      let data = this.dataSKUbytype;
      for (let i = 0; i < data.length; i++) {
        let SKURatio = "";
        if (data[i].ProductPLU.SKURatio != undefined) {
          SKURatio = data[i].ProductPLU.SKURatio;
        }
        let unit = "";
        if (data[i].unit === undefined) {
          unit = "-";
        } else {
          if (data[i].unit.name === undefined) {
            unit = "-";
          } else {
            unit = data[i].unit;
          }
        }
        detail.push({
          remoteImagePath: data[i].remoteImagePath,
          SKUName: data[i].ProductPLU.name,
          unit: unit,
          id: data[i].id,
          objectId: data[i].objectId,
          productPLU: data[i].ProductPLU,
          SKURatio: SKURatio,
          colori: util.generateColor(data[i].indexColor),
        });
      }
      return detail;
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE;
    },
  },
  created() {
    this.getProduct();
    this.getBOM();
    this.getSKUbyType();
    this.shop = this.shops.find((i) => i.objectId === this.shop);
  },
  mounted() {
    document.addEventListener("keydown", this.doSave);
  },
  methods: {
    hasDataChanged() {
      if (this.BOM.length !== this.addlist.length) {
        return true;
      }
      for (let i = 0; i < this.BOM.length; i++) {
        const original = this.BOM[i];
        const updated = this.addlist.find(item => item.productPLU.PLUCode === original.ProductPLU.PLUCode);
        if (!updated || 
            original.quantity !== parseFloat(updated.quantity)|| 
            original.ratio !== parseFloat(updated.ratio)) {
          return true;
        }
      }
      return false;
    },
    confirmSaveProductBOM() {
      if (this.hasDataChanged()) {
        this.showConfirmationModal();
      } else {
        this.loading = true;
        this.confirmModal = true;
      }
    },
    showConfirmationModal() {
      this.loading = true;
      this.confirmSaveChangeModal = true;
    },
    calculate(item, type) {
      item.quantity = parseFloat(item.quantity) || 0;
      item.ratio = parseFloat(item.ratio) || 0;

      if (type === "quantity" && item.ratio !== 0) {
        item.quantity = parseFloat((1 / item.ratio).toFixed(4));
      } else if (type === "ratio" && item.quantity !== 0) {
        item.ratio = parseFloat((1 / item.quantity).toFixed(4));
      }
    },
    doSave(e) {
      if (!(e.keyCode === 83 && e.ctrlKey)) {
        return;
      } else if (e.keyCode === 83 && e.ctrlKey) {
        this.confirmSaveProductBOM();
      }
      e.preventDefault();
    },
    getBOM() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      let params = {
        skuObjectId: this.$route.params.productSKUObjectId,
      };
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      local({
        url: "/api/v1.0/" + uid + "/productsku/getbom",
        params: params,
        headers: headers,
        method: "GET",
      }).then((res) => {
        this.BOM = res.data.data;
        this.originalBOM = JSON.parse(JSON.stringify(this.BOM)); 
        this.BOM.forEach((e) => {
          let SKURatio = "";
          if (e.ProductPLU.SKURatio != undefined) {
            SKURatio = e.ProductPLU.SKURatio;
          }
          let formattedQuantity = (e.quantity).toFixed(4)
          let formattedRatio = (e.ratio).toFixed(4)
          this.addlist.push({
            productPLU: e.ProductPLU,
            id: e.id,
            SKUName: e.ProductPLU.name,
            objectId: e.objectId,
            unit: e.ProductPLU.unit,
            colori: util.generateColor(e.ProductPLU.colori),
            remoteImagePath: e.ProductPLU.remoteImagePath,
            SKURatio: SKURatio,
            quantity: formattedQuantity,
            ratio: formattedRatio,
          });
          this.countAddList = this.addlist.length;
        });
      });
    },
    getSKUbyType() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      let params = {
        shopObjectId: shopObjectId,
        skuType: "P",
        keyword: this.keyword,
        page: 1,
        limit: 50,
      };
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      local({
        url: "/api/v1.0/" + uid + "/productsku/getbytype",
        params: params,
        headers: headers,
        method: "GET",
      }).then((res) => {
        this.dataSKUbytype = res.data.data;
        this.BOM.forEach((e) => {
          let data = res.data.data;
          const index = data.findIndex(
            (x) => x.ProductPLU.objectId === e.ProductPLU.objectId
          );
          if (index != -1) {
            this.dataSKUbytype.splice(index, 1);
          }
        });
      });
    },
    addBOMtoLits(item, index) {
      this.addlist.push({
        productPLU: item.productPLU,
        id: item.id,
        SKUName: item.SKUName,
        objectId: item.productPLU.objectId,
        unit: {
          id: item.unit.id,
          name: item.unit.name,
          objectId: item.unit.objectId,
        },
        colori: item.colori,
        quantity: 1,
        ratio: 1,
        remoteImagePath: item.remoteImagePath,
        SKURatio: item.SKURatio,
      });
      this.items.splice(index, 1);
      this.countAddList = this.addlist.length;
    },
    conremoveRow: function (item, index) {
      var con = confirm(this.$i18n.t("confirmDeleteProduct"));
      if (con == true) {
        this.addlist.splice(index, 1);
        this.items.push(item);
        this.countAddList = this.addlist.length;
        this.removeRow(item);
      }
    },
    removeRow: function (item) {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const plan = this.users.currentPlan;
      const shopObjectId = this.shopObjectId;
      let data = {
        shopObjectId: shopObjectId,
        objectId: item.objectId,
      };
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      local({
        method: "post",
        url: "/api/v1.0/" + uid + "/ProductBOM/softdelete",
        params: { plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.countAddList = this.addlist.length;
          }, 1000)
        )
        .catch(function (error) {
          console.log(error);
        });
    },
    saveProductBOM() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      let data = this.addlist;
      let dataitem = [];
      this.loading = false;
      data.forEach((e) => {
        dataitem.push({
          PLUCode: e.productPLU.PLUCode,
          id: e.productPLU.id,
          name: e.productPLU.name,
          objectId: e.productPLU.objectId,
          unit: {
            id: e.unit.id,
            name: e.unit.name,
            objectId: e.unit.objectId,
          },
          quantity: parseFloat(e.quantity),
          ratio: parseFloat(e.ratio), 
        });
      });
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      local({
        method: "post",
        url: "/api/v1.0/" + uid + "/productsku/addbom",
        data: {
          objectId: this.$route.params.productSKUObjectId,
          BOM: dataitem,
        },
        headers: headers,
        })
        .then(() => {
          setTimeout(() => {
          this.loading = true;
          this.confirmModal = false;
          this.confirmSaveChangeModal = false;
          this.$router.go(-1);
        }, 1000);
      })
        .catch(function (error) {
        console.log(error);
      });
    },
    getProduct() {
      this.loadingButton = false;
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      let params = {
        shopObjectId: shopObjectId,
        categoryObjectId: "",
      };
      let data = {};
      this.loading = false;
      local
        .get(
          "/api/v1.0/" +
            uid +
            "/ProductSKU/data/" +
            this.$route.params.productSKUObjectId,
          {
            params,
          }
        )
        .then((res) => {
          this.data = res.data.data.document;
          data = this.data;
          this.SKUName = data.SKUName;
          this.loadingButton = true;
          this.searchLoadingButton = true;
        });
    },
    getback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.cardadd {
  min-height: 438px;
  max-height: 400px;
  /* border-color: #3abc98; */
  border-color: rgb(218, 216, 216);
}
.form-control {
  cursor: pointer;
}
.custom-select {
  cursor: pointer;
}
.form-control:disabled,
.form-control[readonly] {
  cursor: default;
}
.cursor {
  cursor: pointer;
}
</style>
